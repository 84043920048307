import React from 'react';
import { AppBar, Button, CssBaseline, Typography, Link,Toolbar, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../../resources/Bci_logo.svg"
import order from "../../resources/icon_pedido.svg"
import verify from "../../resources/icon_verificarcob.svg"
import faq from "../../resources/icon_faq.svg"
import flag from "../../resources/flagChile.svg"
import './Style.css'

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: "0.5",
    background:"trasparent"
  },
  link: {
    margin: theme.spacing(1, 1.5),
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"13em"
  },
  flag:{
    margin: theme.spacing(1, 1.5),
    background: "transparent",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
  },
  imgFlag:{
    width:"1.2em"
  },
  linkButton:{
      border: "1px solid #00A94A",
      background:"white",
      fontSize:"10px",
      color:"#9A9A9A",
      textTransform:"capitalize",
      display:"flex",
      justifyContent:"space-around",
      alignItems:"center",
      width:"13em"
  },
  textNav:{
    fontSize:"12px",
    color: "#979797",
    textTransform:"capitalize",
    textAlign:"center",
    paddingLeft:"6em",
    paddingRight:"6em", 
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"17em"
  },
  name:{
    color:"#00A94A",
    marginLeft:".5em"
  }
}));


export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="white" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                <img className="logo_navbar" src={logo} alt="logo"/>
          </Typography>
          <button className="navBar-button">
            <img className="button-image" src={order} alt="orden"/> Crear pedidos
          </button>
          <button className="navBar-button">
          <img className="button-image" src={order} alt="carga masiva"/> Carga masiva 
          </button>
          <button className="navBar-button-variant">
          <img src={verify} alt="verificar cobertura"/> Verificar cobertura
          </button>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle} />
          <Divider orientation="vertical" flexItem/>
          <nav>
            <Typography   noWrap  className={classes.textNav}>
              Hola <strong className={classes.name}>Fernando</strong>
            </Typography>
            <Divider orientation="vertical" flexItem />
          </nav>
          <Divider orientation="vertical" flexItem />   
            <Typography  noWrap className={classes.textNav}>
            <img src={faq} alt="faq"/><small>Faq</small>
            </Typography>
          <Link  href="#" className={classes.flag}>
              <img className={classes.imgFlag} src={flag} alt="flag"/>
            </Link>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}