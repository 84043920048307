import React,{useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import search from "../../resources/icon_search.svg"
import {Container,Button, Grid, Input,IconButton, InputAdornment  } from '@material-ui/core';
import descarga from "../../resources/icon_descarga_g.svg"
import imprimir from "../../resources/icon_imprimir.svg";
import descargados from "../../resources/descarga_gris.svg"
import imprimirdos from "../../resources/imprimir_gris.svg"
import actualizar from "../../resources/icon_refresh.svg"
import arrow from "../../resources/arrow_green.svg"
import Sidebar from '../../common/Sidebar/SidebarComponent'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { NoEncryption } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';


const StyledTableCell = withStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      
    },
    
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: "15px"
  },
  iconButton: {
    width:"max-content",
  },
  input: {
    marginRight: theme.spacing(0),
    flex: 1,
    borderBottom:"1px solid #EBEBEB"
    
  },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      padding: "2px"
    },
  },
}))(TableRow);


const modalidad=<button className="btn_modalidad">99minutos</button>
const status=<button className="btn_status">Entregado</button>
function createData(todos, seguimiento, destino) {
  return { todos, seguimiento, destino, modalidad, status};
}

const rows = [
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    margin: "auto"
  },
  link:{
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"15em",
    marginLeft: "1em"
  },
  printBtn:{
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"11em",
    marginLeft: "1em"
  },
  UpdateBtn:{
    background: " #9A9A9A",
    color:"#ffffff",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"11em",
    marginLeft: "1em"
  },
  input:{
    display:"flex",
    alignItems:"bottom"

  },
  formControl: {
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: false,
  },
  textField: {
    
    width: 140,
  },
});

export default function CustomizedTables() {
  const classes = useStyles();

  
  const [state, setState] = React.useState({
    age: '',
    name: 'hai',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

 

  
  return (
    <div className="container__OrderList">
      <Sidebar/>
      <img src={arrow} alt="order"/><span className="styles__title"> Historial<strong className="title__strong"> de pedidos</strong> </span>

           <div className="styles__containertwo">

      <div className="styles__history">
        <span className="styles__filterhistory"><strong>Filtrar por:</strong></span>
        <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Estado</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
        >
          <MenuItem value="">
            <em>none</em>
          </MenuItem >
          <MenuItem value={10}>Todos</MenuItem>
          <MenuItem value={20}>Entregado</MenuItem>
          <MenuItem value={30}>Cancelado</MenuItem>
          <MenuItem value={40}>Devolución</MenuItem>
          <MenuItem value={50}>Robo y Extravío</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="age-native-simple">Tipo</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
        >
          <MenuItem value="">
            <em>none</em>
          </MenuItem >
          <MenuItem value={10}>Todos</MenuItem>
          <MenuItem value={20}>Same Day</MenuItem>
          <MenuItem value={30}>Next Day</MenuItem>
          <MenuItem value={40}>99 minutos</MenuItem>
          <MenuItem value={40}>CO2 Free</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
      <TextField
        id="date"
        label=" "
        type="date"
        defaultValue="2017-05-24"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </FormControl>
    </div>
 
      
      <div id="style__containersearchistory">
        <Input
          padding="13px 0 7px"
          type="search"
          color="primary"
          startAdornment={
          <InputAdornment position="start">
          <IconButton>
          <img src={search} alt="search"/>
          </IconButton>
          </InputAdornment>
          }
        />
      </div>
      
      <div item lg={6}  xs={12} className="row__butttons">
      <button className="descarga__button">
            <img className="button-image" src={descarga} alt="orden"/> Crear pedidos
        </button>
        <button className="descarga__button">
            <img className="button-image" src={imprimir} alt="orden"/> imprimir
        </button>   
      </div>

      </div>


      <div className="container__table">
      <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell  align="left">Todos</StyledTableCell>
            <StyledTableCell align="left">N° Seguimiento/Fecha</StyledTableCell>
            <StyledTableCell align="left">Destino</StyledTableCell>
            <StyledTableCell align="center">Modalidad</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="left">
            <button className="actualizar__button">
                <img className="button-image" src={actualizar} alt="orden"/> Actualizar
              </button>
            </StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.todos}>
              <StyledTableCell align="left">
                <label className="text__hide">Todos</label>
              </StyledTableCell>
          
              <StyledTableCell align="left">{row.seguimiento}</StyledTableCell>
              <StyledTableCell align="left">{row.destino}</StyledTableCell>
                <StyledTableCell align="center">{row.modalidad}</StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>

              <StyledTableCell align="center">{row.actualizar}
                <img className="img__print" src={descargados} alt="order"/>
                <img className="img__print" src={imprimirdos} alt="order"/>
              </StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
    </div>
  );
}

