import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import SteepTwo from "./CompanyCounter.js"
import SteepSecondTwo from "./CompanyCounterTwo.js"
import styles from "./Register.module.css"



export default class FatherComponent extends Component  {
  constructor(props) {
    super(props);
  }

  onSubmit(e) {
    e.preventDefault()
  }
  render() {

    const renderFormByCountry = mover => {
      switch (mover) {
        case "IsCompleted":
          return <SteepTwo companystate={this.props.companystate} handleRegister ={this.props.handleRegister} formcompleted={this.props.formcompleted} messageError={this.props.messageError} />
          break;
        default:
          return <SteepSecondTwo companystate={this.props.companystate} handleRegister ={this.props.handleRegister} messageError={this.props.messageError}/>
          break;
      }
    };

 
    // this.props.movstepper
    return (
      <div>
        <div style ={this.props.movstepper !=  'IsCompleted' ? {display: "none"} : {display: "block"}}>
         <SteepTwo companystate={this.props.companystate} handleRegister ={this.props.handleRegister} formcompleted={this.props.formcompleted} messageError={this.props.messageError} />

        </div>
        <div style ={this.props.movstepper == 'IsCompleted' ? {display: "none"} : {display: "block"}}>

         <SteepSecondTwo companystate={this.props.companystate} handleRegister ={this.props.handleRegister} messageError={this.props.messageError}/>

        </div>
      </div>
    );
  }
}