import React from 'react'
import styles from "./Register.module.css"
import { Link } from "react-router-dom";
import finish from "../../resources/finish.jpg"

export default function FinishRegister() {
    return (
        <div className={styles.sectionOnefinish}>
            <section className={styles.orgfinish}>

                <label id={styles.titlefinish}>
                    <strong>¡Felicitaciones!</strong>
                </label>

                <label id={styles.titlecounter}>
                    Has registrado tu empresa con éxito
                </label>
                <label id={styles.textpeoplethree}>Revisa tu correo electrónico y sigue las instrucciones</label>
                <a className={styles.finishimg} href="https://client.99minutos.app/sign-in">
            <img  id={styles.finishimg} src={finish} alt="finish"/>
            </a>

            </section>
            <section className={styles.footer}>
 
            <hr className={styles.hr}></hr>
            <div className={styles.footerbtn}>
            <Link className={styles.center} to="CompanyRegister">
            <button id={styles.back}>
                Si deseas registrar una
            </button>
            </Link>
            <Link className={styles.center} to="CompanyRegister">
            <button id={styles.btnpeople}>
                Empresa
            </button>
            </Link>
            </div>
            </section>
        </div>

    )
}
