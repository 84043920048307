import React from 'react';
import {
  BrowserRouter,
  Route,
} from "react-router-dom";

import './resources/styles/App.scss';
//Client Partial Sections
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

//Login & Signup
import Login from './components/Login/LoginComponent';
import Dashboard from "./components/Dashboard/DashboardComponent"

//users
import Users from "./components/Users/UsersComponent"
import OrderList from "./components/OrderList/OrderListComponent"
import OrderHistory from "./components/OrderHistory/OrderHistoryComponent"


//Register
import Register from "./components/Register/RegisterComponent"
import Counter from "./components/Register/StepCounter"
import People from "./components/Register/PeopleCounter"
import PeopleTwo from "./components/Register/PeopleTwoCounter"
import PeopleThree from "./components/Register/PeopleThreeCounter"
import FinishRegister from "./components/Register/FinishRegister"
import CompanyCounter from "./components/Register/CompanyCounter"
import CompanyCounterTwo from "./components/Register/CompanyCounterTwo"
import CompanyCountry from "./components/Register/CompanyCountry"
import CompanyThree from './components/Register/CompanyThree';
import FinishRegisterCompany from "./components/Register/FinishRegisterCompany"
import PeopleRegister from "./components/Register/PeopleRegister"
import CompanyRegister from "./components/Register/CompanyRegister"
import FatherComponent from "./components/Register/FatherComponent"

//Massive
import Massive from "./components/Massive/MassiveComponent"


//Orders
import CreateOrder from "./components/CreateOrder/CreateOrderComponent"
import Confirmation from "./components/ConfirmationOrder/ConfirmationOrderComponent"
import Success from "./components/SuccessOrder/SuccessOrderComponent"
//Masive Load
import MasiveLoad from './components/MasiveLoad/MasiveLoadComponent'



function App() {
  return (
    
      <BrowserRouter>
          <Route exact path={"/"} component={Register}/>
          <Route exact path="/login" component={Login} />
          <Route exact path="/users" component={Users}/>
          <Route exact path={"/create-order"} component={CreateOrder}/>
          <Route exact path={"/confirmation"} component={Confirmation}/>
          <Route exact path={"/success"} component={Success}/>
          <Route exact path="/orders" component={OrderList}/>
          <Route exact path="/history" component={OrderHistory}/>
          <Route exact path="/massive" component={Massive}/>
          <Route exact path="/register" component={Register}/>
          <Route exact path="/counter" component={Counter}/>
          <Route exact path="/people" component={People}/>
          <Route exact path="/peopletwo" component={PeopleTwo}/>
          <Route exact path="/peoplethree" component={PeopleThree}/>
          <Route exact path="/finishregister" component={FinishRegister}/>
          <Route exact path="/companycounter" component={CompanyCounter}/>
          <Route exact path="/companycountertwo" component={CompanyCounterTwo}/>
          <Route exact path="/companycountry" component={CompanyCountry}/>
          <Route exact path="/companythree" component={CompanyThree}/>
          <Route exact path="/finishregistercompany" component={FinishRegisterCompany}/>
          <Route exact path="/peopleregister" component={PeopleRegister}/>
          <Route exact path="/companyregister" component={CompanyRegister}/>
          <Route exact path="/fathercomponent" component={FatherComponent}/>

      </BrowserRouter>
  );
}


export default App;
