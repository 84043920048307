import React, { Component } from 'react'
import { Grid, Container,Button} from "@material-ui/core";
import points from "../../resources/puntoscolores.svg"
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../common/Sidebar/SidebarComponent"
import envelope from "../../resources/icon_sobre.svg"
import check from "../../resources/tilde.svg"
import order from "../../resources/icon_pedido.svg"

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
  NoneColorLink:{
        margin: theme.spacing(1, 1.5),
        background: "#ffffff",
        color:"#979797",
        border:"1px solid  #ffffff",
        fontSize:"15px",
        textTransform:"capitalize",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"12em"
  }
  }));


export default function ConfirmationOrderComponent() {
    const classes = useStyles();
    return (
        <Container>
        <Sidebar/>
        <div className={classes.root} id="createOrder__container">
        <Grid container spacing={3} className="title__order" lg={12}>
            <img src={points} alt="puntos"/>
            <h3>¡Ya casi estamos! Confirma tu pedido por favor</h3>
        </Grid>
        <Grid  className="row__buttons__confirm" lg={12}>
        <Grid item lg={5}>
            <Grid className="subtitle__order">
            <div>
                <label id="label__numbers">01</label>
                <h4 id="color__Text">Datos del pedido</h4>
            </div>
            <span>Editar</span>
            </Grid>
            <hr id="border__decoration"/>
            <Grid className="content__data" container lg={12}>
                <Grid lg={6}>
                <span className="text__bold">Tipo de entrega</span>
                    <button className="button__pedido "> 99minutos</button>    
                </Grid>
                    <Grid  lg={6}>
                        <span className="text__bold"> El tamaño de tu pedido  </span>
                        <Grid className="row__buttons__">
                            <img src={envelope} alt="envelope"/>
                        <div className="size__text">
                            <label>Extra pequeño</label>
                            <small>Menos de 1 Kg.</small>
                        </div>
                    </Grid> 
                    </Grid> 
            </Grid>
        </Grid>
           <Grid item lg={5}>
            <Grid className="subtitle__order">
            <div>
                <label id="label__numbers">02</label>
                <h4 id="color__Text">Retorno del envio</h4>
            </div>
            <span>Editar</span>
            </Grid>
            <hr id="border__decoration"/>
            <Grid className="content__data" container lg={4}>
                <Grid lg={12} className="position__center">
                    <img src={check} alt="check"/><span>Sí, a origen</span>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
       {/*  holi */}
          <Grid className="row__buttons__confirm" lg={12}>
        <Grid item lg={5}>
            <Grid className="subtitle__order">
            <div>
                <label id="label__numbers">03</label>
                <h4 id="color__Text">Datos de recolección</h4>
            </div>
            <span>Editar</span>
            </Grid>
            <hr id="border__decoration"/>
            <Grid className="content__data" container lg={12}>
                <Grid lg={6} className="data_order">
                    <Grid>
                        <span>Dirección</span>
                        <label>Agutinas 1161 7° piso, Santiago Centro, Santiago.</label>
                    </Grid>
                    <Grid>
                        <span>Email</span>
                        <label>fernando@bci.cl</label>
                    </Grid>
                    <Grid>
                        <span>Declaración de contenido</span>
                        <label>Tarjetas de crédito.</label>
                    </Grid>
                </Grid>
                    <Grid  lg={6} className="data_order">
                        <Grid>
                            <span>Email</span>
                            <label>fernando@bci.cl</label>
                        </Grid>
                         <Grid>
                            <span>Teléfono</span>
                            <label>55518765128.</label>
                         </Grid>
                    </Grid> 
            </Grid>
        </Grid>
           <Grid item lg={5}>
            <Grid className="subtitle__order">
            <div>
                <label id="label__numbers">04</label>
                <h4 id="color__Text">Datos de entrega</h4>
            </div>
            <span>Editar</span>
            </Grid>
            <hr id="border__decoration"/>
            <Grid className="content__data" container >
                  <Grid lg={6} className="data_order">
                    <Grid>
                        <span>Dirección</span>
                        <label>Agutinas 1161 7° piso, Santiago Centro, Santiago.</label>
                    </Grid>
                    <Grid>
                        <span>Email</span>
                        <label>rfercho180@gmail.com</label>
                    </Grid>
                    <Grid>
                        <span>Contenido</span>
                        <label>Documentación.</label>
                    </Grid>
                    <Grid>
                        <span>Firma de documentación</span>
                        <label>Solicitar registro de huella.</label>
                    </Grid>
                </Grid>
                <Grid  lg={6} className="data_order">
                        <Grid>
                            <span>Nombre</span>
                            <label>Juan Torres</label>
                        </Grid>
                         <Grid>
                            <span>Teléfono</span>
                            <label>55518765128.</label>
                         </Grid>
                        <Grid>
                            <span>Tipo de solicitud</span>
                            <label>Retiro</label>
                        </Grid>
                         <Grid>
                            <span>Notas adicionales</span>
                            <label>Entregar el próximo lunes</label>
                         </Grid>
                    </Grid> 
            </Grid>
        </Grid>
        </Grid>
        <Grid lg={12} className="title__order">
           
          <button className="button__confpedido"> <img src={order} alt="order"/> Confirmar pedido</button>   
            <Button href="#"  variant="outlined" className={classes.NoneColorLink}>
                 <small>Cancelar pedido</small>
          </Button>
        </Grid>
        </div>
    </Container>
    )
}
