import React from 'react'
import { Grid, TextField } from "@material-ui/core";
  
  export default function StepOneComponent() {
      return (
            <Grid lg={12} className="form__order" spacing={3}>
                <Grid item lg={6} >
                    <h4>Datos de quien <label id="color__Text">enviará </label>el paquete</h4>
                    <Grid  
                        container
                         spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid item lg={6}>
                        <TextField id="standard-basic" label="Dirección" />
                        </Grid>
                        <Grid lg={3}>
                        <TextField id="standard-basic" label="N° dirección" />
                        </Grid>
                        <Grid item lg={3}>
                        <TextField id="standard-basic" label="Piso/Oficina" />
                        </Grid>
                    </Grid>
                    <Grid                        
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}
                    >
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Comuna" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="C.P" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid  lg={6}>
                    <h4>Datos de quien <label id="color__Text">recibirá </label>  el paquete</h4>
                    <Grid  
                        container
                         spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="C.P" />
                        </Grid>
                        <Grid item lg={3}>
                        <TextField id="standard-basic" label="N° dirección" />
                        </Grid>
                        <Grid item lg={3}>
                        <TextField id="standard-basic" label="Piso/Oficina" />
                        </Grid>
                    </Grid>
                    <Grid                        
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}
                    >
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Comuna" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="C.P" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
      )
  }
