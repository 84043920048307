import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Grid } from "@material-ui/core";
import Sidebar from "../../common/Sidebar/SidebarComponent"


const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    alternativeLabel: {
      border: "none"
    }
  }));


export default function DashboardComponent() {
    const classes = useStyles();
    return (
        <Container>
            <Sidebar/>
                <div className={classes.root} id="createOrder__container">
                <Grid container spacing={3} className="title__order" lg={12}>
                    <h1>Dashboard</h1>
                </Grid>
                    <div className="margin__Container">
                    </div>
                </div>
        </Container>
    )
}
