import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Stepper, Container,Step, StepLabel, Button, Typography, Grid } from "@material-ui/core";
import Sidebar from "../../common/Sidebar/SidebarComponent"
import StepOne from "../../components/StepOne/StepOneComponent"
import StepTwo from "../../components/StepTwo/StepTwoComponent"
import StepThree from "../../components/StepThree/StepThreeComponent"
import points from "../../resources/puntoscolores.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  alternativeLabel: {
    border: "none"
  }
}));

function getSteps() {
  return [
    "0",
    "1",
    "2",
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <StepOne/>;
    case 1:
      return <StepTwo/>;
    case 2:
      return <StepThree/>;
      default:
      return  "steps completed";
  }
}

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container>
      <Sidebar/>
      <div className={classes.root} id="createOrder__container">
      <Grid container spacing={3} className="title__order" lg={12}>
        <img src={points}  alt="points"/>
        <h1>Estás por crear un pedido</h1>
      </Grid>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="margin__Container">
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
