import React from 'react'
import {   
    makeStyles,
} from '@material-ui/core/styles';
import { Grid, TextField,Container,InputLabel,FormControl, MenuItem, Select } from "@material-ui/core";
import check from "../../resources/tilde.svg"


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "12em",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));


  export default function StepOneComponent() {
    const classes = useStyles();
      return (
          <Container>
            <Grid lg={12} className="form__order">
                <Grid item lg={6}>
                    <h4>Datos de quien <label id="color__Text">enviará </label>el paquete</h4>
                    <Grid  
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Nombre completo" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Rut" />
                        </Grid>
                    </Grid>
                    <Grid  
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Teléfono" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Email" />
                        </Grid>
                    </Grid>
                    <Grid  
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={12}>
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                            Declaración de contenido
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            displayEmpty
                            className={classes.selectEmpty}
                            >
                            <MenuItem value="">
                               Selecciona una declaración
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid  lg={6}>
                    <h4>Datos de quien <label id="color__Text">recibirá </label>  el paquete</h4>
                    <Grid  
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Nombre completo" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Rut" />
                        </Grid>

                    </Grid>
                    <Grid                        
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}
                    >
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Teléfono" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Email" />
                        </Grid>
                    </Grid>
                    <Grid                        
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}
                    >
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Contenido" />
                        </Grid>
                        <Grid lg={6}>
                        <TextField id="standard-basic" label="Tipo de solicitud" />
                        </Grid>
                    </Grid>
                    <Grid                        
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}
                    >
                    </Grid>
                    <Grid                        
                     container
                        direction="row"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                        <Grid lg={12}>
                        <TextField id="standard-basic" label="Firma de documentación" />
                        </Grid>
                        <Grid lg={12}>
                        <TextField id="standard-basic" label="Notas adicionales" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid lg={12} className="bottom__form">
                <h4>¿Tu envío tiene <span id="color__Text">Retorno</span>?</h4>
                <div> <img src={check} alt="check"/> <label>Sí, a origen</label></div>
                <div> <img src={check} alt="check"/><label>Otro</label></div>
            </Grid>
          </Container>
      )
  }
