import React from 'react'
import {   
    makeStyles,
} from '@material-ui/core/styles';
import { Grid, Button, Container } from "@material-ui/core";
import envelope from "../../resources/icon_sobre.svg"



const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "12em",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
        link: {
    padding:"10px",
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"13em"
  },
    linkButton:{
      border: "1px solid #00A94A",
      background:"white",
      fontSize:"10px",
      color:"#9A9A9A",
      textTransform:"capitalize",
      display:"flex",
      justifyContent:"space-around",
      alignItems:"center",
      width:"13em"
  },
  }));


  export default function StepOneComponent() {
    const classes = useStyles();
      return (
          <Container>
            <Grid lg={12} className="form__order">
                <Grid item lg={6}>
                    <Grid  
                        container
                        direction="column"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                            <h4>Elige el <label id="color__Text">tipo de envío</label></h4>
                            <Grid className="row__buttons">
                                <Button href="#" color="primary" variant="outlined" className={classes.link}>
                                <small>Carga masiva</small> 
                                </Button>
                                <Button href="#" color="primary" variant="outlined" className={classes.linkButton}>
                                <small>Verificar cobertura</small>
                                </Button>
                            </Grid>
                    </Grid>
                </Grid>
                <Grid  lg={6}>
                    <Grid  
                        container
                        direction="column"
                        justify="center"
                        alignItems="center" 
                        lg={12}>
                            <label id="color__Text">El tamaño</label><h4>de tu pedido será </h4>
                            <Grid className="row__buttons">
                                <img src={envelope} alt="envelope"/>
                                <div className="size__text">
                                    <label>Extra pequeño</label>
                                    <small>Menos de 1 Kg.</small>
                                </div>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>

          </Container>
      )
  }
