import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import styles from "./Register.module.css"
import { Link } from "react-router-dom";
import axios from 'axios';
// Persona Física
import SteepOne from "./StepCounter.js"
import SteepTwo from "./FatherComponent.js"
import SteepThree from "./PeopleThreeCounter.js"
import Finish from "./FinishRegister.js"
import logo99 from "../../resources/99minutos_Logo.svg"

export default class CompanyRegister extends Component {
    constructor() {
        super();
        this.state = {
            movstepper: 'IsCompleted',
            data: {},
            messageError:  { email: '', company: '' },

        };
        this.stateTwo = {
            info: []

        };
    }

    componentDidMount() {
        this.stepper = new Stepper(document.querySelector('#stepper1'), {
            linear: false,
            animation: true

        })
    }

    handleRegister = (step = 'one', data = [], next = true) => {
        this.setState({
            data: {
                ...this.state.data,
                [step]: data
            }
        }, () => {
            console.log(this.state.data)
            if (next) {
                this.stepper.next()
            }
            if (step === 'pasword') {
                this.consumir(this.state.data)
                this.stepper.next()
            }

        })
    }
    formcompleted = (completed) => {
        if (completed) {
            this.setState({ ...this.state, movstepper: 'IsCompleted' })
        } else {
            this.setState({ ...this.state, movstepper: 'IsInCompleted' })
        }
    }

    consumir = async (info) => {

        const payload = {
            "contact": {
                "name": info.companycountertwo.name,
                "lastName": info.companycountertwo.lastname,
                "email": info.companycountertwo.email,
                "phone": info.company.companyphone,
                "cargcompany": info.companycountertwo.cargo
            },
            "company": {
                "name": info.company.companyname,
                "adress": '',
                "gyre": info.company.rubro,
                "bunisessName": info.company.razonsocial,


            },
            "user": {
                "username": info.companycountertwo.email,
                "password": info.pasword.password,
            },
            "country": info.country,
            "lat": "",
            "long": "",
            "origin": "/register",
        }
        try {
            const response = await axios({
                method: "post",
                url: 'https://client-dot-precise-line-76299minutos.appspot.com/api/v1/profile/company',
                data: payload
            });
            if (response.status === 201) {
                console.log("Registro exitoso!")
                window.location.replace("/finishregistercompany");
            } else if (response.status === 200) {
                if (response.data === 'existEmail') {
                    this.formcompleted(false)
                    this.stepper.to(2)
                    this.setState({ ...this.state, messageError: { email: 'Este correo ya existe', company: '' } });


                } else if (response.data === 'existCompany') {
                    this.formcompleted(true)
                    this.stepper.to(2)
                    this.setState({ ...this.state, messageError: { company: 'Este compañia ya existe', email: '' } });
                } else {
                    this.stepper.to(1)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }



    onSubmit(e) {
        e.preventDefault()
    }

    render() {


        return (

            <div className={styles.sectionPeople}>
                <div className={styles.logo99}> 
                <a className={styles.logo99} href="https://www.99minutos.com/">          
                <img id={styles.logo99} src={logo99} alt="logo99"/>
                </a>
                </div>

                <div className={styles.steeper} >
                    <div id="stepper1" class="bs-stepper" >
                    <div className={styles.logo99grnd}> 
                    <a className={styles.logo99grnd} href="https://www.99minutos.com/">          
                        <img id={styles.logo99} src={logo99} alt="logo99"/>
                    </a>
                </div>
                        <div className={styles.steeperheader} style={{ pointerEvents: 'none' }}>
                            <div class="bs-stepper-header" style={{ pointerEvents: 'none' }}>
                                <div class="step" data-target="#test-l-1" style={{ pointerEvents: 'none' }}>
                                    <button class="step-trigger" onClick={(e) => false}>
                                        <span class="bs-stepper-circle">1</span>
                                    </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-2" style={{ pointerEvents: 'none' }}>
                                    <button class="step-trigger" onClick={(e) => false}>
                                        <span class="bs-stepper-circle">2</span>
                                    </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-3" style={{ pointerEvents: 'none' }} >
                                    <button class="step-trigger" onClick={(e) => false}>
                                        <span class="bs-stepper-circle">3</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-content">
                            <form onSubmit={this.onSubmit}>
                                <div id="test-l-1" class="content">
                                    <div class="form-group">
                                        <SteepOne handleRegister={this.handleRegister} />
                                    </div>
                                </div>
                                <div id="test-l-2" class="content">
                                    <div class="form-group">
                                        <SteepTwo handleRegister={this.handleRegister} messageError={this.state.messageError} formcompleted={this.formcompleted} movstepper={this.state.movstepper} companystate={this.state} />
                                    </div>
                                </div>
                                <div id="test-l-3" class="content text-center">
                                    <div class="form-group">
                                        <SteepThree handleRegister={this.handleRegister} />

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <section className={styles.footer}>
                    <hr className={styles.hr}></hr>
                    <div className={styles.footerbtn}>

                        <button id={styles.back} onClick={() => this.stepper.previous()} type="submit" >
                            Si deseas registrar una
                    </button>

                        <Link className={styles.center} to="PeopleRegister">
                            <button id={styles.btnpeople}>
                                Persona Física
                        </button>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}