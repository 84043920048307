import React from 'react'
import styles from "./Register.module.css"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useForm, Controller } from 'react-hook-form'
import FormHelperText from '@material-ui/core/FormHelperText';

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }

    }
})(TextField);



const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    margin: {
        margin: theme.spacing(1),
        width: "90%"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function CompanyCounter(props) {
    const classes = useStyles();
    const { register, errors, control, handleSubmit } = useForm();
    const handleRegister = props.handleRegister
    const formcompleted = props.formcompleted;
    const companystate = props.companystate;

    const onSubmit = (data) => {
        data["rubro"] = state.rubro
        console.log('submit', data)
        formcompleted(false)
        handleRegister('company', data, false)
    }
    const [state, setState] = React.useState({
        rubro: ''
    });

    const handleChange = (event) => {
        const name = event.target.name;
        console.log(event.target.value, name)
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    const messageError = props.messageError


    return (
        <div >
            <section className={styles.org}>

                <label id={styles.titleone}>
                    <strong>Cuéntanos</strong>
                </label>

                <label id={styles.titlecounter}>
                    Sobre tu empresa
                </label>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.selectForms}>
                        <div id={styles.formOne}>
                            <FormControl fullWidth className={classes.margin}>
                                <Controller
                                    name="companyname"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="companyname"
                                            labelWidth={40}
                                            helperText={errors.companyname ? errors.companyname.message : messageError.company}
                                            error={errors.companyname}
                                            label="Nombre de la empresa"
                                            placeholder="99minutos"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^[a-z 0-9]+$/i,
                                            message: 'Campo incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div id={styles.formThree}>
                            <FormControl fullWidth className={classes.margin}>
                                <Controller
                                    name="razonsocial"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="razonsocial"
                                            labelWidth={40}
                                            helperText={errors.razonsocial ? errors.razonsocial.message : " "}
                                            error={errors.razonsocial}
                                            label="Razón social"
                                            placeholder="Razón Social"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^[a-z ]+$/i,
                                            message: 'Campo incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>

                        </div>
                        <div id={styles.formTwoo}>
                            <FormControl fullWidth className={classes.margin} name={"rubro"} >

                                <InputLabel className={classes.margin} shrink htmlFor="rubro" name={"rubro"}>
                                    Rubro
                                </InputLabel>

                                <NativeSelect className={classes.margin}
                                    value={state.rubro}
                                    name={"rubro"}
                                    onChange={handleChange}
                                    required={' '}
                                    style={{
                                        marginTop: '23px'
                                    }}
                                    inputProps={{
                                        name: 'rubro',
                                        id: 'rubro',
                                    }}
                                >
                                    <option value="">Ej: Servicios públicos</option>
                                    <option value={1}>Servicios públicos</option>
                                    <option value={2}>Servicios privados</option>
                                    <option value={3}>Servicios de transporte</option>
                                    <option value={4}>Turismo</option>
                                    <option value={5}>Educación</option>
                                    <option value={6}>Salud</option>
                                    <option value={7}>Cultura y Entretenimiento</option>
                                    <option value={8}>Comercial</option>
                                    <option value={9}>Comisionista</option>
                                    <option value={10}>Mayorista</option>
                                    <option value={11}>Minirista o detallista</option>
                                    <option value={12}>Otro</option>
                                </NativeSelect>

                            </FormControl>

                        </div>
                        <div id={styles.formFour}>
                            <FormControl fullWidth className={classes.margin}>
                                <Controller
                                    name="companyphone"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="companyphone"
                                            label="Teléfono comercial"
                                            placeholder="+5256768749"
                                            labelWidth={40}
                                            helperText={errors.companyphone ? errors.companyphone.message : " "}
                                            error={errors.companyphone}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^([0-9+]){8,20}$/,
                                            message: 'Número incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.btnextpeople}>
                        <button id={styles.btnstyle} type="submit">
                            Siguiente
                    </button>
                    </div>
                </form>
            </section>

        </div>

    )
}
