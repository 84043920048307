import React, { Component, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'
import styles from "./Register.module.css"
import { Link } from "react-router-dom";
import axios from 'axios';
// Persona Física
import SteepOne from "./PeopleCounter.js"
import SteepTwo from "./CompanyCountry.js"
import SteepThree from "./CompanyThree.js"
import Finish from "./FinishRegister.js"
import logo99 from "../../resources/99minutos_Logo.svg"


export default class PeopleRegister extends Component {
    constructor() {
        super();

        this.state = {

            name: 'React',
            data: {},
            messageError:{email:'', company:''},
            nametwo: {
                "lat": "",
                "long": "",
                "origin": "/register"
            }

        };
        this.stateTwo = {
            info: []

        };
       
    }


    componentDidMount() {
        this.stepper = new Stepper(document.querySelector('#stepper1'), {
            linear: false,
            animation: true
        })

    }

    handleRegister = (step = 'one', data) => {
        this.setState({
            data: {
                ...this.state.data,
                [step]: data
            }
        }, () => {
            console.log(this.state.data)
            if (step === 'pasword') {
                this.consumir(this.state.data)
            }
            this.stepper.next()
        })
    }

    consumir = async (info) => {

        const payload = {
            "contact": {
                "name": info.people.name,
                "lastName": info.people.lastname,
                "email": info.people.email,
                "phone": info.people.phone
            },
            "company": {
                "name": info.people.name + info.people.lastname + Math.floor(Date.now() / 1000),
                "adress": '',
                "gyre": 2,
                "bunisessName": ''
            },
            "user": {
                "username": info.people.email,
                "password": info.pasword.password,
            },
            "country": info.country,
            "lat": "",
            "long": "",
            "origin": "/register",
        }
        try {
            const response = await axios({
                method: "post",
                url: 'https://client-dot-precise-line-76299minutos.appspot.com/api/v1/profile/company',
                data: payload
            });
            if (response.status === 201) {
                console.log("Registro exitoso!")
                window.location.replace("/finishregister");
            }else if(response.status === 200){
                if (response.data === 'existEmail'){
                    this.stepper.to(1)
                    this.setState({...this.state, messageError: {email:'Este correo ya existe',company: ''}});
                    
                
                }else if(response.data === 'existCompany'){
                    this.stepper.to(1)
                    this.setState({...this.state, messageError: {company:'Este compañia ya existe', email: ''}});
                }else{
                    this.stepper.to(1)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }


    onSubmit(e) {
        e.preventDefault()
    }

    render() {

        return (
            
            <div className={styles.sectionPeople}>
                <div className={styles.logo99}> 
                <a className={styles.logo99} href="https://www.99minutos.com/">          
                <img id={styles.logo99} src={logo99} alt="logo99"/>
                </a>
                </div>

                <section className={styles.steeper}>
                    
                    <div id="stepper1" class="bs-stepper">
                <div className={styles.logo99grnd}> 
                    <a className={styles.logo99grnd} href="https://www.99minutos.com/">          
                        <img id={styles.logo99} src={logo99} alt="logo99"/>
                    </a>
                </div>
                        <div className={styles.steeperheader} style={{pointerEvents: 'none'}}>
                            <div class="bs-stepper-header" style={{pointerEvents: 'none'}}>
                                <div class="step" data-target="#test-l-1" style={{pointerEvents: 'none'}}>
                                    <button class="step-trigger" onClick={(e)=> false}>
                                        <span class="bs-stepper-circle">1</span>
                                    </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-2" style={{pointerEvents: 'none'}}>
                                    <button class="step-trigger" onClick={(e)=> false}>
                                        <span class="bs-stepper-circle">2</span>
                                    </button>
                                </div>
                                <div class="line"></div>
                                <div class="step" data-target="#test-l-3" style={{pointerEvents: 'none'}}>
                                    <button class="step-trigger" onClick={(e)=> false}>
                                        <span class="bs-stepper-circle">3</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="bs-stepper-content">
                            <form onSubmit={this.onSubmit}>
                                <div id="test-l-1" class="content">
                                    <div class="form-group">
                                        <SteepOne handleRegister={this.handleRegister} messageError={this.state.messageError} />
                                    </div>
                                </div>
                                <div id="test-l-2" class="content">
                                    <div class="form-group">
                                        <SteepTwo handleRegister={this.handleRegister} />

                                    </div>
                                </div>
                                <div id="test-l-3" class="content text-center">
                                    <div class="form-group">
                                        <SteepThree handleRegister={this.handleRegister} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <section className={styles.footer}>
                    <hr className={styles.hr}></hr>
                    <div className={styles.footerbtn}>

                        <button id={styles.back} onClick={() => this.stepper.previous()} type="submit" >
                            Si deseas registrar una
                    </button>

                        <Link className={styles.center} to="CompanyRegister">
                            <button id={styles.btnpeople}>
                                Empresa
                        </button>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}
