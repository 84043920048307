import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './TableStyle.css'

const StyledTableCell = withStyles((theme) => ({
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const generateReport = <button className="reportButton">Generar Reporte</button>
const deleteButton = <img id="color__cancel" src={require("./cancelButton.png")} width="25px" height="25px" alt="cancel buton"></img>

function createData(usuario, nombre, sucursal, funcion, observaciones) {
  return { usuario, nombre, sucursal, funcion, observaciones, generateReport, deleteButton };
}

const rows = [
  createData('claudioolivares@bci.cl', "Claudio Olivares", "Almirante Latorre", "Gerente Comercial", "Órdenes generadas: 123"),
  createData('claudioolivares@bci.cl', "Claudio Olivares", "Almirante Latorre", "Gerente Comercial", "Órdenes generadas: 123"),
  createData('claudioolivares@bci.cl', "Claudio Olivares", "Almirante Latorre", "Gerente Comercial", "Órdenes generadas: 123"),
  createData('claudioolivares@bci.cl', "Claudio Olivares", "Almirante Latorre", "Gerente Comercial", "Órdenes generadas: 123"),
  createData('claudioolivares@bci.cl', "Claudio Olivares", "Almirante Latorre", "Gerente Comercial", "Órdenes generadas: 123"),
];

const useStyles = makeStyles({
  table: {
      width: "auto",
      margin: "auto"
  },
});

export default function CustomizedTables() {
  const classes = useStyles();

  return (
    <TableContainer >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell><strong>Usuario</strong></StyledTableCell>
            <StyledTableCell align="rigth">Nombre</StyledTableCell>
            <StyledTableCell align="rigth">Sucursal</StyledTableCell>
            <StyledTableCell align="rigth">Función</StyledTableCell>
            <StyledTableCell align="rigth">Observaciones</StyledTableCell>
            <StyledTableCell align="rigth"></StyledTableCell>
            <StyledTableCell align="rigth"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.usuario}>
              <StyledTableCell component="th" scope="row">
                <strong>{row.usuario}</strong>
              </StyledTableCell>
              <StyledTableCell align="right">{row.nombre}</StyledTableCell>
              <StyledTableCell align="right">{row.sucursal}</StyledTableCell>
              <StyledTableCell align="right">{row.funcion}</StyledTableCell>
              <StyledTableCell align="right">{row.observaciones}</StyledTableCell>
              <StyledTableCell align="center">{row.generateReport}</StyledTableCell>
              <StyledTableCell align="center">{row.deleteButton}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}