import React from 'react'
import styles from "./Register.module.css"
import { Link } from "react-router-dom";
import logo99 from "../../resources/99minutos_Logo.svg"

export default function registerComponent() {
    return (
        <div className={styles.sectionOne}>
            <section className={styles.orgtitle}>
                <head>
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                </head>
                
                <label class="animate__animated animate__bounceInDown" id={styles.titleone}>
                    Que bueno contar contigo en  <strong>99 minutos</strong>
                </label>

                <label class="animate__animated animate__bounceInDown" id={styles.titletwo}>
                    ¿Qué tipo de cuenta deseas registrar?
                </label>

                <div className={styles.buttonregister}>
                    <Link to="peopleregister">
                        <button class="animate__animated animate__rubberBand" id={styles.btnstyle}>
                            Persona física
                    </button>
                    </Link>
                    <Link to="companyregister">
                        <button class="animate__animated animate__rubberBand" id={styles.btnstyle}>
                            Empresa
                    </button>
                    </Link>
                </div>
            </section>
        </div>

    )
}