import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {  Container,Button, Grid, Input,IconButton, InputAdornment  } from '@material-ui/core';
import search from "../../resources/icon_search.svg"
import userIcon from "../../resources/icon_crearusuario.svg"
import download from "../../resources/icon_descarga_g.svg"
import arrow from "../../resources/arrow_green.svg"
import SideBar from "../../common/Sidebar/SidebarComponent"
import CustomizedTables from './UserTable'
import './UserComponentStyle.css'

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center'
    },
    iconButton: {
      width:"max-content",
    },
    input: {
      marginRight: theme.spacing(0),
      flex: 1,
      borderBottom:"1px solid #EBEBEB "
    },
    divider: {
      height: 28,
      margin: 4,
    },
    link:{
      margin: theme.spacing(1, 1.5),
      background: " #00A94A",
      color:"#ffffff",
      border:"1px solid  #00A94A",
      fontSize:"10px",
      textTransform:"capitalize",
      display:"flex",
      justifyContent:"space-around",
      alignItems:"center",
      width:"13em"
    }
  }));

  
  export default function UsersComponent() {
    const classes = useStyles();
      return (
        <div className="container__User">
        <SideBar/>
        
        <div className="title__users">
        <img src={arrow} alt="order"/><span className="styles__title"> Mis<strong className="title__strong"> usuarios</strong> </span>
        </div>
        <div className="styles__lines"> 
        <div className="styles__stateusers">
          <label className="styles__colaboradores">Colaboradores<div className="table__totalusers">368</div></label>
        </div>
        <Input
          padding="13px 0 7px"
          type="search"
          color="#017CC6"
          startAdornment={
          <InputAdornment position="start">
          <IconButton>
          <img src={search} alt="search"/>
          </IconButton>
          </InputAdornment>
          }
        />
          <div className="user__bottons">
        <button className="descarga__button">
            <img className="button-image" src={userIcon} alt="orden"/> Crear usuario
        </button>

        <button className="descarga__button">
            <img className="button-image" src={download} alt="orden"/> Descargar excel
        </button>
      </div>
      </div>

    
        <CustomizedTables />
        </div>
      )
  }
  

