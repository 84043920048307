import React from 'react'
import Sidebar from "../../common/Sidebar/SidebarComponent"
import { makeStyles } from '@material-ui/core/styles';
import { Container, Button, Grid } from "@material-ui/core";
import like from "../../resources/icon_thumbup.svg"
import order from "../../resources/icon_pedido.svg"


const useStyles = makeStyles((theme) => ({
        link: {
    padding:"10px",
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    borderRadius:"12px",
    fontSize:"13px",
    textTransform:"capitalize",
    display:"flex",
    marginTop:"10px",
    justifyContent:"space-around",
    alignItems:"center",
    width:"16em"
  },
}));

export default function SuccessOrderComponent() {
      const classes = useStyles();
    return (
    <Container className="background__success">
        <Sidebar/>
        <div className={classes.root} id="createOrder__container">
            <Grid container spacing={3} className="title__Success" lg={12}>
                <img src={like}  alt="like"/>
                <h3>Tu pedido fue confirmado con éxito</h3>
                <Button href="#" color="primary" variant="outlined" className={classes.link}>
                    <img src={order} alt="order"/> <small>Crear un nuevo pedido</small>
                </Button>
            </Grid>
        </div>
      </Container>
    )
}
