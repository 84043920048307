import React from 'react';
import { Grid, Button,Container } from "@material-ui/core"
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ImageLogin from "./ImageComponent"
import bci from "../../resources/Bci_logo.svg"
import logo99 from "../../resources/99minutos_Logo.svg"



  const LoginButton = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      padding: '8px',
      fontSize: '14px',
      border: '1px solid ',
      lineHeight: 1.5,
      backgroundColor: '#fffff',
      borderColor: '#00A94A',
      color:'#00A94A'
    },
  })(Button);
  
  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

export default function LoginComponent() {
  const classes = useStyles();
  return (
    <div className="Container__login">
    <Grid container item lg={7} xs={12} className="container__image__login">
      <ImageLogin />
    </Grid>
    <Grid container item lg={5}  xs={12} className="form__login">
      <Container id="form__container">
      <label id="label__power">Powered by</label>
      <div  className="container_logo">
        <img id="img__bci" src={bci} alt="logo"/>
        <img id="login__logo" src={logo99} alt="logo99"/>
          
    
      </div>
      <Grid className="title__login">
        
        <h3>¡Qué bueno verte de nuevo!</h3>
        <label id="login__ingresar">Ingresa a tu cuenta</label>
      </Grid>
      <Grid className="inputs__login">
        <span>Usuario</span>
        <input type="mail" />
      </Grid>
      <Grid className="inputs__login">
        <span>Contraseña</span>
        <input type="password" />
      </Grid>
      <Grid className="container__password">
        <div>
          <input id="login__check" type="checkbox"/> <label id="remember__login">Recordar contraseña</label>
        </div>
          <a href="" >¿Olvidaste tu contraseña?</a>
    </Grid>
    <div className="login_position_btn_terms">
      <LoginButton className={classes.margin}  size="large" variant="outlined" color="#00A94A">Ingresar</LoginButton>
    </div>
      <Grid  item xs={12} id="container__terms">
        <label id="label__terms">Términos y condiciones</label>
        <label id="label__terms">Políticas de privacidad</label>
      </Grid>
      </Container>
    </Grid>
  </div>
  )
}

