import React,{useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Container,Button, Grid, Input,IconButton, InputAdornment  } from '@material-ui/core';
import descarga from "../../resources/icon_descarga_g.svg"
import imprimir from "../../resources/icon_imprimir.svg";
import arrow from "../../resources/arrow_green.svg"
import Sidebar from '../../common/Sidebar/SidebarComponent'
import Typography from "@material-ui/core/Typography";
import descargatabla from "../../resources/descarga_gris.svg"
import imprimitabla from "../../resources/imprimir_gris.svg"


const StyledTableCell = withStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    
    },
    
  },
  
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: "15px"
  },
  iconButton: {
    width:"max-content",
  },
  input: {
    marginRight: theme.spacing(0),
    flex: 1,
    borderBottom:"1px solid #EBEBEB"
    
  },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      padding: "2px"
      
    },
  },
}))(TableRow);



const status=<button className="btn_status">Entregado</button>
function createData(todos, seguimiento, destino) {
  return { todos, seguimiento, destino, status};
}

const rows = [
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    margin: "auto"
  },
  link:{
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"15em",
    marginLeft: "1em"
  },
  input:{
    display:"flex",
    alignItems:"bottom"

  }
});

export default function CustomizedTables() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <div className="container__OrderList">

    <Sidebar/>
      <span className="styles__massivetitle"><img src={arrow} alt="order"/> Esta es tu <strong className="title__strong">carga masiva</strong> </span>

           <div className="styles__btnmassive">
      
      <div item lg={6}  xs={12} className="row__butttons">
        <button className="descarga__button">
            <img className="button-image" src={descarga} alt="orden"/> Crear pedidos
        </button>
        <button className="descarga__button">
            <img className="button-image" src={imprimir} alt="orden"/> imprimir
        </button>   
      </div>

      </div>


      <div className="cotain__massive">
      <TableContainer >
      <Table className={classes.table} aria-label="customized table" >
        <TableHead>
          <TableRow>
            <StyledTableCell  align="left">N°</StyledTableCell>
            <StyledTableCell align="left">Folio</StyledTableCell>
            <StyledTableCell align="left">Cliente</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center"></StyledTableCell>
            
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.todos}>
              <StyledTableCell align="left">
                <label className="text__hide">Todos</label>
              </StyledTableCell>
          
              <StyledTableCell align="left">{row.seguimiento}</StyledTableCell>
              <StyledTableCell align="left">{row.destino}</StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>

              <StyledTableCell align="center">{row.actualizar}
                <img className="img__print" src={descargatabla} alt="order"/>
                <img className="img__print" src={imprimitabla} alt="order"/>
              </StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
    </div>
  );
}