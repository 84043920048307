import React from 'react'
import styles from "./Register.module.css"
import chile from "../../resources/flag_Chile01.svg"
import mexico from "../../resources/flag_Mex01.svg"
import colombia from "../../resources/flagColom01.svg"
import peru from "../../resources/flag_Peru01.svg"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import click from "../../resources/icon_correct.svg"
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";


const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {

        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)"
        },
        "input:hover ~ &": {
            backgroundColor: "#106ba3"
        }
    },
    checkedIcon: {
        content: '""'
    },
    "input:hover ~ &": {
        backgroundColor: "#106ba3"
    }
});

function Mexico(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<img id={styles.flagsize} src={click} alt="click" />}
            icon={<img id={styles.flagsize} src={mexico} alt="mexico" />}
            {...props}
        />

    );
}
function Chile(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<img id={styles.flagsize} src={click} alt="click" />}
            icon={<img id={styles.flagsize} src={chile} alt="chile" />}
            {...props}
        />

    );
}
function Colombia(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<img id={styles.flagsize} src={click} alt="click" />}
            icon={<img id={styles.flagsize} src={colombia} alt="colombia" />}
            {...props}
        />

    );
}
function Peru(props) {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            checkedIcon={<img id={styles.flagsize} src={click} alt="click" />}
            icon={<img id={styles.flagsize} src={peru} alt="peru" />}
            {...props}
        />

    );
}

export default function StepCounter(props) {

    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(' ');
    const handleRegister = props.handleRegister

    const handleRadioChange = (event) => {
        // console.log(event)
        setValue(event.target.value);
        setHelperText(' ');
        setError(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let second = true;

        if (value === 'MEX') {
            setError(false);
            second= false;
        } else if (value === 'CHL') {
            setError(false);
            second= false;
        } else if (value === 'COL') {
            setError(false);
            second= false;
        } else if (value === 'PER') {
            setError(false);
            second= false;
        } else {
            setHelperText('Por favor selecciona una opción');
            setError(true);
        } 

        if(! second){
            handleRegister('country',value)
        }
    };

    return (
        <div>
            <section className={styles.org}>

                <label id={styles.titleone}>
                    <strong>¡Comencemos!</strong>
                </label>

                <label id={styles.titlecounter}>
                    Solo te tomará pocos minutos
                </label>

                <div className={styles.selectCountry}>
                    <label className={styles.titlecountry}>
                        <strong>Selecciona el país donde te encuentras</strong>
                    </label>
                    <form onSubmit={handleSubmit}>
                        <FormControl component="fieldset" error={error}>
                            <div className={styles.flags} >
                                <RadioGroup className= {styles.flagsgood}
                                    Value="female"
                                    aria-label="gender"
                                    name="customized-radios"
                                    onChange={handleRadioChange}
                                >

                                    <div id={styles.sectionmex}>
                                        <FormControlLabel
                                            value="MEX"
                                            control={<Mexico />}
                                            label="México"
                                            labelPlacement="bottom"
                                        />
                                    </div>

                                    <div id={styles.sectionchl}>
                                        <FormControlLabel
                                            value="CHL"
                                            control={<Chile />}
                                            label="Chile" labelPlacement="bottom"
                                        />
                                    </div>
                                    <div id={styles.sectioncol}>
                                        <FormControlLabel
                                            value="COL"
                                            control={<Colombia />}
                                            label="Colombia"
                                            labelPlacement="bottom"
                                        />
                                    </div>
                                    <div id={styles.sectionper}>
                                        <FormControlLabel
                                            value="PER"
                                            control={<Peru />}
                                            label="Perú"
                                            labelPlacement="bottom"
                                        />
                                    </div>
                                </RadioGroup>

                            </div>
                     
                                <FormHelperText className={styles.textalert}>{helperText}
                                </FormHelperText>
                        
                        </FormControl>
                        <div className={styles.btnext}>

                            <button id={styles.btnstyle} type="submit">
                                Siguiente
                            </button>

                        </div>
                    </form>


                </div>
            </section>

        </div>

    )
}
