import React from 'react'
import styles from "./Register.module.css"
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useForm, Controller } from 'react-hook-form'
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },

}));

export default function PeopleThreeCounter(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [helperText, setHelperText] = React.useState(' ');
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    passwordTwo: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    PasswordTwo: false
  });
  const handleRegister = props.handleRegister

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handlerChange = (prop) => (event) => {
    //console.log('handlerChange',event)
    setValues({ ...values, [prop]: event.target.value });
  };
  const handlerClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handlerClickPasswordTwo = () => {
    setValues({ ...values, PasswordTwo: !values.PasswordTwo });
  };

  const handlerMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlerMouseDownPasswordTwo = (event) => {
    event.preventDefault();
  };

  const { register, errors, control, handleSubmit } = useForm();



  const onSubmit = (data) => {
   
    if (data.cnfpassword != data.password) {
      setHelperText("Las contraseñas deben ser identicas");
      return false;
    } else {
      setHelperText('')
      handleRegister('pasword', data)
      return true;
    }
  }
 

  return (

    <div>
      <section className={styles.org}>

        <label id={styles.titleone}>
          <strong>¡Ya estamos finalizando!</strong>
        </label>

        <label id={styles.titlecounter}>
          Por favor, ingresa tu contraseña
                </label>
        <label id={styles.titlecounter}>
          para tu nueva cuenta.
                </label>
        <div id={styles.gridtitle}><label id={styles.textpeoplethree}>Verifica que contenga una mayúscula, al menos un número y sea mayor a 8 caracteres</label> </div>

        <div className={styles.selectCountry}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.pasword}>
              <div id={styles.formOne}>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <Controller
                    name="password"
                    as={
                      <TextField
                        id="passwordOne"
                        placeholder="***************"
                        label="Contraseña"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        helperText={errors.password ? errors.password.message : ""}
                        error={errors.password}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onInput={null/*handlerChange("password")*/}

                        InputProps={{

                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handlerClickShowPassword}
                                onMouseDown={handlerMouseDownPassword}
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}

                      />}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Este campo es obligatorio',
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)([A-Za-z]|[^ ]){8,20}$/,
                        message: 'Contraseña incorrecta'
                      }
                    }}
                  />
                </FormControl>
              </div>
              <div id={styles.formThree}>
                <FormControl className={clsx(classes.margin, classes.textField)}>
                  <Controller
                    name="cnfpassword"
                    as={
                      <TextField
                        id="passwordTwo"
                        placeholder="***************"
                        label="Confirmar contraseña"
                        type={values.PasswordTwo ? "text" : "password"}
                        value={values.passwordTwo}
                        helperText={errors.cnfpassword ? errors.cnfpassword.message : " "}
                        error={errors.cnfpassword}
                        onInput={null/*handlerChange("passwordTwo")*/}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          endAdornment: (

                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle passwordTwo visibility"
                                onClick={handlerClickPasswordTwo}
                                onMouseDown={handlerMouseDownPasswordTwo}
                              >
                                {values.PasswordTwo ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Este campo es obligatorio',
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*)([A-Za-z]|[^ ]){8,20}$/,
                        message: 'Contraseña incorrecta'
                      }
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <FormHelperText
                style={{ textAlign: "center", color: 'red' }}>{helperText}
              </FormHelperText>
            <div className={styles.sizecheck}>
             
              <Checkbox
                checked={checked}
                name="check"
                required="hola"
                onChange={handleChange}                
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
               
               <label>Acepto los <a style={{fontSize: '0.8rem', color: '#85c440'}} href="https://www.mx.99minutos.com/terminos.html" target="_blank">Terminos y Condiciones</a> del servicio </label> 
             
            </div>
           


            <div className={styles.btnext}>
              
              <button id={styles.btnstyle} type="submit">
                Finalizar
              </button>

            </div>
          </form>

        </div>
      </section>

    </div>
  )
}