import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, CssBaseline, AppBar, List, ListItem,ListItemIcon, ListItemText} from '@material-ui/core';
import disabledOrders from "../../resources/icon_encurso.svg"
import disabledHistory from "../../resources/icon_historial.svg"
import disabledUsers from "../../resources/icon_usuarios.svg"
import NavBar from "../Header/HeaderComponent"
import logo from "../../resources/99minlogoHD.png"
import './style.css'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    width: "100%" ,
    zIndex: theme.zIndex.drawer + 1,    
  },
  drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor:"#2C2E2E",
    width: drawerWidth,
  },
  itemSidebar:{
      color:"white"
  },
  itemLogoSidebar:{
    color:"white",
    display:"flex",
    flexDirection:"column"
  },
  listItems:{
    height:"30vh",
    display:"flex",
    padding:".5em",  
    paddingTop:"2em",
    flexDirection:"column",
    justifyContent:"space-between"
  },
  logo99:{
      width:"8em"
  },
  listLogo:{
    position:"fixed",
    bottom:0,
   right:"33%"
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  subtitle:{
    color:"#B1B1B1"
  }
}));


export default function PermanentDrawerLeft() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed"   className={classes.appBar}>
            <NavBar/>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <List className={classes.listItems}>
            <ListItem button  className={classes.itemSidebar}>
                <ListItemIcon><img src={disabledOrders} alt="orders"/></ListItemIcon>
                Pedidos en curso
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText  />
            </ListItem>
            <ListItem button  className={classes.itemSidebar}>
                <ListItemIcon><img src={disabledHistory} alt="history"/></ListItemIcon>
                Historial de pedidos
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText  />
            </ListItem>
            <ListItem button  className={classes.itemSidebar}>
                <ListItemIcon><img src={disabledUsers} alt="users   "/></ListItemIcon>
                Mis usuarios
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText  />
            </ListItem>
        </List>
            <div className="side-bar-logo">
              <p>Powered by</p>
              <img src={logo} alt="99 minutos logo" width="130px" height="auto"></img>
            </div>
      </Drawer>
      <main className={classes.content}>
      </main>
    </div>
  );
}