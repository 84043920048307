import React, { Component } from 'react';
import login from "../../resources/99Bci_pic_login.svg"


class imageLogin extends Component {

  render() {
    return (
        <img id="position__img__login" src={login} alt="imgLogin"/>
    );
  }
}
export default imageLogin