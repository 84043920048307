import React, { Component, useState } from 'react';
import styles from "./Register.module.css"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { useForm, Controller } from 'react-hook-form'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import Stepper from 'bs-stepper'


const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    }
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    margin: {
        margin: theme.spacing(1),
        width: "90%"
    }

}));

    export default function PeopleCounter(props) {
    
    const classes = useStyles();
    const { register, errors, control, handleSubmit } = useForm();
    const handleRegister = props.handleRegister
    const onSubmit = (data) => {
        handleRegister('people', data)
    }
   const messageError = props.messageError
     
    return (
        <div  >
            <section className={styles.org}>

                <label id={styles.titleone}>
                    <strong>¡Comencemos!</strong>
                </label>

                <label id={styles.titlecounter}>
                    Solo te tomará pocos minutos
                </label>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={styles.selectForm}>
                        <div id={styles.formOne}>
                            <FormControl fullWidth className={classes.margin}>
                                <Controller
                                    name="name"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="name"
                                            labelWidth={40}
                                            helperText={errors.name ? errors.name.message : " "}
                                            label="Nombre"
                                            error={errors.name}
                                            placeholder="Nombre"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^[a-z ]+$/i,
                                            message: 'Campo incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div id={styles.formThree}>
                        <FormControl fullWidth className={classes.margin} >
                                <Controller
                                    name="lastname"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="lastname"
                                            label="Apellido"
                                            placeholder="Bernardo"
                                            labelWidth={40}
                                            helperText={errors.lastname ? errors.lastname.message : " "}
                                            error={errors.lastname}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^[a-z ]+$/i,
                                            message: 'Campo incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div id={styles.formTwo}>
                        <FormControl fullWidth className={classes.margin} >
                                <Controller
                                    name="email"
                                    as={
                                        <CssTextField

                                            className={classes.margin}
                                            id="email"
                                            labelWidth={40}
                                            label="Email"
                                            helperText={errors.email ? errors.email.message : messageError.email }
                                            error={errors.email}
                                            placeholder="julio@99minutos.com"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                                            message: 'Correo no valido'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div id={styles.formFour}>
                            <FormControl fullWidth className={classes.margin}>
                                <Controller
                                    name="phone"
                                    as={
                                        <CssTextField
                                            className={classes.margin}
                                            id="phone"
                                            label="Teléfono"
                                            placeholder="5256768749"
                                            labelWidth={40}
                                            helperText={errors.phone ? errors.phone.message : " "}
                                            error={errors.phone}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    }
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Este campo es obligatorio',
                                        pattern: {
                                            value: /^([0-9+]){8,20}$/,                                        
                                            message: 'Número incorrecto'
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={styles.btnextpeople}>
                        
                        <button id={styles.btnstyle} type="submit">
                            Siguiente
                        </button>
                    </div>
                </form>

            </section>
        </div>

    )
}
