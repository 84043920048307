import React,{useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import search from "../../resources/icon_search.svg"
import {Container,Button, Grid, Input,IconButton, InputAdornment  } from '@material-ui/core';
import descarga from "../../resources/icon_descarga_g.svg"
import imprimir from "../../resources/icon_imprimir.svg";
import descargados from "../../resources/descarga_gris.svg"
import imprimirdos from "../../resources/imprimir_gris.svg"
import actualizar from "../../resources/icon_refresh.svg"
import arrow from "../../resources/arrow_green.svg"
import Sidebar from '../../common/Sidebar/SidebarComponent'
import Paper from '@material-ui/core/Paper';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { SelectAll } from '@material-ui/icons';
import Checkbox from "@material-ui/core/Checkbox";


const StyledTableCell = withStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      
    },
    
  },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    padding: "15px"
  },
  iconButton: {
    width:"max-content",
  },
  input: {
    marginRight: theme.spacing(0),
    flex: 1,
    borderBottom:"1px solid #EBEBEB"
    
  },
}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      padding: "2px"
    },
  },
}))(TableRow);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 80,
    fontWeight: theme.typography.fontWeightRegular.justifyContent,
    marginRight: theme.spacing(4),
    "&$selected": {
      color: "black",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "black"
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "#017CC6"
  }
})(Tabs);
const modalidad=<button className="btn_modalidad">99minutos</button>
const status=<button className="btn_status">Entregado</button>
function createData(todos, seguimiento, destino) {
  return { todos, seguimiento, destino, modalidad, status};
}

const rows = [
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
  createData('1', '123456789   12345678', 'Chalco Estado de México'),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    margin: "auto"
  },
  link:{
    background: " #00A94A",
    color:"#ffffff",
    border:"1px solid  #00A94A",
    fontSize:"10px",
    textTransform:"capitalize",
    display:"flex",
    justifyContent:"space-around",
    alignItems:"center",
    width:"15em",
    marginLeft: "1em"
  },
  input:{
    display:"flex",
    alignItems:"bottom"

  }
});

export default function CustomizedTables() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedAllRow, setSelectedAllRow]=useState(false);

  const selectAll=(e)=>{
    setSelectedAllRow(e.target.checked);
  }

  
  return (
    <div className="container__OrderList">
      <Sidebar/>
      <img src={arrow} alt="order"/><span className="styles__title"> Pedidos<strong className="title__strong"> en curso</strong> </span>

      <div className="styles__containertwo">

      <div className="styles__stateFilters">
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="">
          <AntTab label="Todos" /><div className="table__total">368</div>
          <AntTab label="Creado" /><div className="table__numbers">368</div>
          <AntTab label="Recolectado" /><div className="table__numbers">7</div>  
          <AntTab label="En camino" /><div className="table__numbers">7</div>
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
      </div>
      
      <div id="style__containersearchorder">
        <Input
          padding="13px 0 7px"
          type="search"
          color="#017CC6"
          startAdornment={
          <InputAdornment position="start">
          <IconButton>
          <img src={search} alt="search"/>
          </IconButton>
          </InputAdornment>
          }
        />
      </div>
      
      <div item lg={6}  xs={12} className="row__butttonsorder">
        <button className="descarga__button">
            <img className="button-image" src={descarga} alt="orden"/>Descargar ordenes
        </button>
        <button className="descarga__button">
            <img className="button-image" src={imprimir} alt="orden"/> imprimir
        </button>        
      </div>

      </div>


      <div className="container__table">
      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell  align="left">Todos<Checkbox  size={"small"} color={"default"} onChange={(e)=>selectAll(e)} value='todos'/></StyledTableCell>
            <StyledTableCell align="left">N° Seguimiento/Fecha</StyledTableCell>
            <StyledTableCell align="left">Destino</StyledTableCell>
            <StyledTableCell align="center">Modalidad</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="left">
              <button className="actualizar__button">
                <img className="button-image" src={actualizar} alt="orden"/> Actualizar
              </button>
            </StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.todos}>
              <StyledTableCell align="left">
                <label className="text__hide">Todos</label>
                <Checkbox size={"small"} color={"default"} checked={selectedAllRow} value={Math.random()}/>
              </StyledTableCell>
          
              <StyledTableCell align="left">{row.seguimiento}</StyledTableCell>
              <StyledTableCell align="left">{row.destino}</StyledTableCell>
                <StyledTableCell align="center">{row.modalidad}</StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>

              <StyledTableCell align="center">{row.actualizar}
                <img className="img__print" src={descargados} alt="order"/>
                <img className="img__print" src={imprimirdos} alt="order"/>
              </StyledTableCell>

            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </div>
    </div>
  );
}

