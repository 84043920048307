import React from 'react'
import styles from "./Register.module.css"
import chile from "../../resources/flag_Chile01.svg"
import mexico from "../../resources/flag_Mex01.svg"
import colombia from "../../resources/flagColom01.svg"
import peru from "../../resources/flag_Peru01.svg"
import { Link } from "react-router-dom";


export default function PeopleTwoCounter() {
    return (
        <div className={styles.sectionOne}>
            <section className={styles.org}>

                <div className={styles.selectCountry}>
                    <label className={styles.titlecountry}>
                        <strong>Selecciona el país donde te encuentras</strong>
                    </label>

                    <div className={styles.flags}>

                        <div id={styles.sectionmex}>
                            <button className={styles.btncountry}>
                                <img id={styles.flagsize} src={mexico} alt="mexico" />
                            </button>
                            <label id={styles.textcountry}>México</label>
                        </div>
                        <div id={styles.sectionchl}>
                            <button className={styles.btncountry}>
                                <img id={styles.flagsize} src={chile} alt="chile" />
                            </button>
                            <label id={styles.textcountry}>Chile</label>
                        </div>
                        <div id={styles.sectioncol}>
                            <button className={styles.btncountry}>
                                <img id={styles.flagsize} src={colombia} alt="chile" />
                            </button>
                            <label id={styles.textcountry}>Colombia</label>
                        </div>
                        <div id={styles.sectionper}>
                            <button className={styles.btncountry}>
                                <img id={styles.flagsize} src={peru} alt="chile" />
                            </button>
                            <label id={styles.textcountry}>Perú</label>
                        </div>

                    </div>
                    <div className={styles.btnext}>
                        <Link className={styles.center} to="peoplethree">
                            <button id={styles.btnstyle}>
                                Siguiente
                    </button>
                        </Link>
                    </div>

                </div>
            </section>
            <section className={styles.footer}>
                <hr className={styles.hr}></hr>
                <div className={styles.footerbtn}>
                    <button id={styles.back}>
                        Si deseas registrar una
            </button>

                    <Link className={styles.center} to="people">
                        <button id={styles.btnpeople}>
                            Persona Física
            </button>
                    </Link>
                </div>
            </section>
        </div>

    )
}
